export const getContacts = () => [
  {
    color: '#71747A',
    icon: 'fas fa-question-circle',
    key: I18n.t('react.shared.help_center.contacts.support'),
    link: 'https://support.elopage.com/seller',
  },
  {
    color: '#71747A',
    icon: 'fas fa-graduation-cap',
    key: I18n.t('react.shared.help_center.contacts.elopage_academy'),
    link: 'https://elopage.com/s/elopage-academy/',
  },
  {
    color: '#71747A',
    icon: 'fas fa-laptop',
    key: I18n.t('react.shared.help_center.contacts.webinars'),
    link: 'https://pages.elopage.com/webinare-trainings/',
  },
  {
    color: '#71747A',
    icon: 'fab fa-youtube-square',
    key: I18n.t('react.shared.help_center.contacts.youtube'),
    link: 'https://www.youtube.com/channel/UCJDY1g8DPniUShkAJV2V76A',
  },
  {
    color: '#71747A',
    icon: 'fab fa-facebook-square',
    key: I18n.t('react.shared.help_center.contacts.facebook'),
    link: 'https://www.facebook.com/elopagecom/?ref=br_rs',
  },
]

export const getQuickLinks = () => [
  {
    category: 'create',
    title: I18n.t('react.cabinet.seller_statistics.quick_actions.create.title'),
    items: [
      {
        key: 'products',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.create.products'),
        link: '/cabinet/products/new',
      },
      {
        key: 'coupons',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.create.coupons'),
        link: '/cabinet/coupons/new',
      },
      {
        key: 'shop_themes',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.create.shop_themes'),
        link: '/cabinet/shop_themes/new',
      },
      {
        key: 'membership_themes',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.create.membership_themes'),
        link: '/cabinet/membership_themes/new',
      },
    ],
  },
  {
    category: 'market',
    title: I18n.t('react.cabinet.seller_statistics.quick_actions.market.title'),
    items: [
      {
        key: 'affiliate_nodes',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.market.affiliate_nodes'),
        link: '/cabinet/affiliate_nodes',
      },
      {
        key: 'affiliate_redirections',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.market.affiliate_redirections'),
        link: '/cabinet/affiliate_redirections',
      },
      {
        key: 'affiliate_programs',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.market.affiliate_programs'),
        link: '/cabinet/affiliate_programs/new',
      },
      {
        key: 'overview',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.market.overview'),
        link: '/cabinet/analysis/traffic',
      },
    ],
  },
  {
    category: 'search',
    title: I18n.t('react.cabinet.seller_statistics.quick_actions.search.title'),
    items: [
      {
        key: 'orders',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.search.orders'),
        link: '/cabinet/orders',
      },
      {
        key: 'payers',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.search.payers'),
        link: '/cabinet/payers',
      },
      {
        key: 'transfers',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.search.transfers'),
        link: '/cabinet/transfers',
      },
      {
        key: 'logs',
        title: I18n.t('react.cabinet.seller_statistics.quick_actions.search.logs'),
        link: '/cabinet/logs/emails',
      },
    ],
  },
]

export const getQuestions = () => [
  {
    key: 'support',
    title: I18n.t('react.shared.help_center.support.title'),
    content: I18n.t('react.shared.help_center.support.content'),
    button: I18n.t('react.shared.help_center.support.button'),
    action: 'toggleTokenIdModal',
    link: 'https://support.elopage.com/hc/de/requests/new',
  },
  {
    key: 'typeform',
    title: I18n.t('react.shared.help_center.typeform.title'),
    content: I18n.t('react.shared.help_center.typeform.content'),
    button: I18n.t('react.shared.help_center.typeform.button'),
    link: 'https://elopagecom.typeform.com/to/vBiaqR',
  },
]

export const SUPPORT = {
  link: 'https://support.elopage.com/seller/s/contactsupport',
}

export const SUPPORT_LINKS = [
  {
    label: I18n.t('react.shared.nav.help_center'),
    link: 'https://support.elopage.com/hc/de',
    id: 'help_center',
  },
  {
    label: I18n.t('react.shared.nav.support'),
    link: 'https://support.elopage.com/hc/de/requests/new',
    id: 'support',
  },
]

export const SELLER_SUPPORT_LINKS = [
  {
    label: I18n.t('react.shared.nav.business_app'),
    link: 'https://elopage.com/business-app/',
    id: 'business_app',
  },
  ...SUPPORT_LINKS,
]
export const UPGRADE_PLAN_LINK = 'https://elopage.com/preise'

export const CONTACT_US_LINK = 'https://elopage.chilipiper.com/router/end-free-trial-phase'
