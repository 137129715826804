import Zapier from 'images/integration_settings/Zapier.svg'
import Klicktipp from 'images/integration_settings/klicktipp.svg'
import ActiveCampaign from 'images/integration_settings/Activa campaign.svg'
import Mailchimp from 'images/integration_settings/mailchimp.svg'
import GetResponse from 'images/integration_settings/Get response.svg'
import MailingWork from 'images/integration_settings/Mailing_work.svg'
import SupportChat from 'images/integration_settings/Support chat.svg'
import ElopageApi from 'images/integration_settings/ElopageAPI.svg'
import Vimeo from 'images/integration_settings/Vimeo.svg'
import Tracify from 'images/integration_settings/tracify.png'

import { INTEGRATIONS } from './integrationsShared.constants'

export * from './integrationsShared.constants'

export const INTEGRATIONS_KEYS = Object.keys(INTEGRATIONS)

export const EMAIL_PROVIDERS_ICONS = {
  activeCampaign: ActiveCampaign,
  mailChimp: Mailchimp,
  getResponse: GetResponse,
  mailingWork: MailingWork,
}

export const INTEGRATIONS_ICONS = {
  ...EMAIL_PROVIDERS_ICONS,
  klickTipp: Klicktipp,
  zapier: Zapier,
  chat: SupportChat,
  api: ElopageApi,
  vimeoVideos: Vimeo,
  hubspot: 'HubSpot',
  tracify: Tracify,
}

export const INTEGRATIONS_INPUTS = {
  zapier: [
    {
      fluid: true,
      name: 'accessToken',
      type: 'text',
    },
  ],
  activeCampaign: [
    {
      fluid: true,
      name: 'buyerListId',
      type: 'select',
    },
  ],
  mailChimp: [
    {
      fluid: true,
      name: 'buyerListId',
      type: 'select',
    },
  ],
  getResponse: [
    {
      fluid: true,
      name: 'buyerCampaignId',
      type: 'select',
    },
  ],
  mailingWork: [
    {
      fluid: true,
      name: 'buyerListId',
      type: 'select',
    },
  ],
  chat: [
    {
      fluid: true,
      name: 'body',
      type: 'textarea',
    },
  ],
  api: [
    {
      fluid: true,
      name: 'apiSecret',
      type: 'text',
    },
    {
      fluid: true,
      name: 'accessToken',
      type: 'text',
    },
  ],
  vimeoVideos: [
    {
      fluid: true,
      name: 'accessToken',
      type: 'text',
    },
  ],
  tracify: [
    {
      fluid: true,
      name: 'tracifyCsId',
      type: 'text',
    },
    {
      fluid: true,
      name: 'tracifyApiKey',
      type: 'text',
    },
    {
      fluid: true,
      name: 'tracifyIsStaging',
      type: 'toggle',
    },
  ],
}

export const INTEGRATION_FORMS = {
  getResponse: I18n.t('react.cabinet.integration_form.get_response.description'),
  hubSpot: I18n.t('react.cabinet.integration_form.hubspot.description'),
  klickTipp: I18n.t('react.cabinet.integration_form.klick_tipp.description'),
  mailChimp: I18n.t('react.cabinet.integration_form.mail_chimp.description'),
  mailingWork: I18n.t('react.cabinet.integration_form.mailing_work.description'),
}

export const INTEGRATIONS_FORMS_USERNAME_TOOLTIP = {
  mailingWork: 'mailing_work_username',
  klickTipp: 'klick_tipp_username',
}

export const INTEGRATIONS_FORMS_PASSWORD_TOOLTIP = {
  mailingWork: 'mailing_work_password',
  klickTipp: 'klick_tipp_password',
}

export const WARNING_INTEGRATION_FORMS = {
  getResponse: I18n.t('react.cabinet.integration_form.get_response.warning_description'),
  mailingWork: I18n.t('react.cabinet.integration_form.mailing_work.warning_description'),
}

export const EMAIL_AUTOMATIONS_TOOLTIP = {
  order: I18n.t('react.cabinet.help_icon.order_events.content'),
  payment: I18n.t('react.cabinet.help_icon.payment_events.content'),
  refund: I18n.t('react.cabinet.help_icon.refund_events.content'),
  chargeback: I18n.t('react.cabinet.help_icon.chargeback_events.content'),
  lesson: I18n.t('react.cabinet.help_icon.lesson_events.content'),
  quiz: I18n.t('react.cabinet.help_icon.quiz_events.content'),
}

export const HUBSPOT_TEMPLATE_PLACEHOLDER = {
  createDeal: `irb(main):006:0> puts i.deal_create_template
  {
    "dealname": "{{product.name}}",
    "pipeline": "xxx",
    "dealstage": "{{deal_stage_id}}",
    "deal_currency_code": "{{currency}}",
    "amount": "amount"
  }`,
  updateDeal: `puts i.deal_update_template
  {
    "dealname": "{{product.name}}",
    "pipeline": "xxx",
    "dealstage": "{{deal_stage_id}}"
  }`,
  createContact: `puts i.contact_create_template
  {
    "firstname": "{{payer.first_name}}",
    "lastname": "{{payer.last_name}}",
    "country": "{{payer.country}}",
    "email": "{{payer.email}}",
    "phone": "{{payer.phone}}",
    "address": "{{payer.street}} {{payer.street_number}}",
    "city": "{{payer.city}}",
    "zip": "{{payer.zip}}",
    "company": "{{payer.company}}"
  }`,
  updateContact: `puts i.contact_update_template
  {
    "country": "{{payer.country}}",
    "phone": "{{payer.phone}}",
    "address": "{{payer.street}} {{payer.street_number}}",
    "city": "{{payer.city}}",
    "zip": "{{payer.zip}}",
    "company": "{{payer.company}}"
  }`,
}

export const HUBSPOT_CONNECTION_STATUS = {
  active: 'active',
  paused: 'paused',
}
