const socialMenuConfigs = [
  {
    id: 'facebook',
    href: 'https://m.facebook.com/elopagede/',
    icon: 'fab fa-facebook-f',
  },
  {
    id: 'twitter',
    href: 'https://twitter.com/elopage_com',
    icon: 'fab fa-twitter',
  },
  {
    id: 'linkedin',
    href: 'https://www.linkedin.com/company/elopage/',
    icon: 'fab fa-linkedin-in',
  },
  {
    id: 'instagram',
    href: 'https://instagram.com/elopage_de',
    icon: 'fab fa-instagram',
  },
]

export default socialMenuConfigs
