import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import AccountWidget from 'ui/AccountWidget'
import EloModal from 'ui/EloModal'

import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'

import { SIZES } from '@elo-kit/constants/general.constants'
import { ELOPAGE_PROFILE_CABINETS, ELOPAGE_CABINETS_URL, STATUSES, ROOT_URL } from 'constants/general.constants'
import {
  PROFILE_TYPES,
  ACTIVE_PROFILES,
  getProfileDetails,
  UNACTIVE_PROFILES,
  STARTED_WITH_ELOPAGE_KEY,
} from 'constants/profile.constants'

import { TEAM_MEMBER_OVERVIEW_PATH } from 'constants/routes/teamMember.constants'
import { SALES_TEAM_MEMBER_OVERVIEW_PATH } from 'constants/routes/salesTeamMember.constants'
import { setCookies, getCookies, removeCookies } from 'libs/common/cookies'
import { removeTokens } from 'utils/requests.utils'
import { FINGER_PRINT_KEY } from 'utils/fingerprint.utils'
import { getProfileFullName } from 'utils/user.utils'

import { BecomeSellerModal } from './become-seller-modal/BecomeSellerModal'

import { logOut } from '../../../../app/api/session.api'
import { fetchList as fetchPayerSellersList } from '../../../../payer/api/sellers.api'

import './accounts-modal.scss'

const CreateSellerProfileButton = ({ onClick }) => (
  <div>
    {`${I18n.t('react.shared.want_create_own_products')} `}
    <span onClick={onClick} className='accounts-modal__new-account-btn'>
      {I18n.t('react.shared.become_seller_for_free')}
    </span>
  </div>
)

interface ProfileSwitchingModalProps {
  currentProfile: string
  sellerStore?: any
  publisherStore?: any
  eloPublisherStore?: any
  teamMemberStore?: any
  salesTeamMemberStore?: any
  payerStore?: any
  toggle: () => void
  isOpen: boolean
  username: string
  userStore?: any
  onClose?: () => void
}

export const ProfileSwitchingModal: React.FC<ProfileSwitchingModalProps> = observer(
  ({
    userStore: {
      item: { profileTypes, email, sellerUsername },
    },
    currentProfile,
    sellerStore,
    publisherStore,
    eloPublisherStore,
    teamMemberStore,
    salesTeamMemberStore,
    payerStore,
    toggle,
    isOpen,
    username,
    userStore,
    onClose,
  }) => {
    const [payerSellersList, setPayerSellersList] = useState([])
    const [becomeSellerModalOpen, setBecomeSellerModalOpen] = useState(false)

    useEffect(() => {
      profileTypes.forEach((profile) => {
        if (profile !== currentProfile) {
          const profileStore = getProfileStore(profile)
          if (profileStore && !profileStore.item.id) {
            profileStore.fetchItem()
          }
        }

        if (profile === ACTIVE_PROFILES.payer) fetchSellersList()
      })
    }, [])

    const fetchSellersList = async () => {
      const resp = await fetchPayerSellersList()
      const { data } = resp || {}
      if (data) {
        setPayerSellersList(data.list)
      }
    }

    const becomeSellerModalToggle = () => setBecomeSellerModalOpen(!becomeSellerModalOpen)

    const getProfileStore = (profile) => {
      let profileStore
      switch (profile) {
        case ACTIVE_PROFILES.publisher:
          profileStore = publisherStore
          break
        case ACTIVE_PROFILES.eloPublisher:
          profileStore = eloPublisherStore
          break
        case ACTIVE_PROFILES.teamMember:
          profileStore = teamMemberStore
          break
        case ACTIVE_PROFILES.salesTeamMember:
          profileStore = salesTeamMemberStore
          break
        case ACTIVE_PROFILES.payer:
          profileStore = payerStore
          break
        case ACTIVE_PROFILES.seller:
          profileStore = sellerStore
          break
        default:
          profileStore = null
      }

      return profileStore
    }

    const logOutAction = () => {
      logOut().then(() => {
        window.sessionStorage.removeItem(STARTED_WITH_ELOPAGE_KEY)
        removeTokens(false)
        removeCookies(FINGER_PRINT_KEY)
      })
    }

    const accounts = profileTypes.filter(
      (item) => item !== UNACTIVE_PROFILES.manager && item !== UNACTIVE_PROFILES.reseller
    )
    const adminAccessToken = getCookies('admin_access_token') as string
    const showAdminSwitcher = profileTypes.indexOf('manager') > -1 && !adminAccessToken
    const hideCreateSellerProfile = profileTypes.indexOf(ACTIVE_PROFILES.seller) < 0

    const teamMemberAccClick = (teamMember, isTeamMemberActive) => {
      Promise.all([
        setCookies('cabinet_profile_type', 'team_member'),
        setCookies('tm_selected_seller', teamMember.username),
      ]).then(
        () => (window.location.href = `${ROOT_URL}${TEAM_MEMBER_OVERVIEW_PATH}/${isTeamMemberActive ? 'invites' : ''}`)
      )
    }

    const salesTeamMemberAccClick = (salesTeamMember, isSalesTeamMemberActive) => {
      Promise.all([
        setCookies('cabinet_profile_type', 'sales_team_member'),
        setCookies('tm_sales_team_id', salesTeamMember.salesTeamId),
      ]).then(
        () =>
          (window.location.href = `${ROOT_URL}${SALES_TEAM_MEMBER_OVERVIEW_PATH}/${
            isSalesTeamMemberActive ? 'invites' : ''
          }`)
      )
    }

    return (
      <EloModal
        title={I18n.t('react.shared.accounts')}
        cancel={false}
        size={SIZES.large}
        className='accounts-modal'
        {...{
          isOpen,
          toggle,
          onClose,
        }}
      >
        <div className='accounts-modal__widgets-container'>
          {accounts.map((profile, index) => {
            const profileStore = getProfileStore(profile)
            const {
              item: { teamMemberInvites, salesTeamMemberInvites, fullName, userProfile = {} },
            } = profileStore
            const profileDetails = getProfileDetails()[PROFILE_TYPES[profile]]
            const profileTypeText = I18n.t('react.shared.profile_type_account', {
              profileType: profileDetails.title,
            })
            const profileName = currentProfile === ACTIVE_PROFILES.seller ? getProfileFullName(userProfile) : fullName
            const isAccountActive = username === (profileName || sellerUsername || email) && currentProfile === profile
            const isPayer = profile === ACTIVE_PROFILES.payer
            const accountName =
              profile === ACTIVE_PROFILES.seller
                ? sellerUsername
                : fullName || `${userProfile.firstName || ''} ${userProfile.lastName || ''}`

            if (
              (profile === ACTIVE_PROFILES.salesTeamMember || profile === ACTIVE_PROFILES.teamMember) &&
              !teamMemberInvites &&
              !salesTeamMemberInvites
            ) {
              return <LoadingMask key={index} />
            } else {
              if (profile === ACTIVE_PROFILES.teamMember && teamMemberInvites) {
                return teamMemberInvites.map((teamMember, index) => {
                  const isAccountActive =
                    getCookies('tm_selected_seller') === teamMember.username &&
                    currentProfile === ACTIVE_PROFILES.teamMember
                  const isTeamMemberActive = teamMember.state !== STATUSES.approved
                  const isTeamMemberAppActive = teamMember.isAppActive
                  return (
                    <AccountWidget
                      key={`team-member-${index}`}
                      id={`${profileStore.item.id}-${index}`}
                      icon={profileDetails.icon}
                      accountName={teamMember.username || ''}
                      profileTypeText={teamMember.roleName}
                      handleAccountClick={() =>
                        isTeamMemberAppActive && teamMemberAccClick(teamMember, isTeamMemberActive)
                      }
                      active={isAccountActive}
                      disabled={!isTeamMemberAppActive}
                    />
                  )
                })
              }

              if (profile === ACTIVE_PROFILES.salesTeamMember && salesTeamMemberInvites) {
                return salesTeamMemberInvites.map((salesTeamMember, index) => {
                  const isSalesTeamMemberDisabled =
                    salesTeamMember.state === STATUSES.declined || !salesTeamMember.isAppActive
                  const isAccountActive =
                    getCookies('tm_sales_team_id') === String(salesTeamMember.salesTeamId) &&
                    currentProfile === ACTIVE_PROFILES.salesTeamMember
                  const isSalesTeamMemberActive = salesTeamMember.state !== STATUSES.accepted
                  if (salesTeamMember.state !== STATUSES.rejected) {
                    return (
                      <AccountWidget
                        key={`sales-team-member-${index}`}
                        id={`${profileStore.item.id}-${index}`}
                        icon={
                          <svg
                            width='32'
                            height='32'
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM17.1329 8.13636C17.1329 7.50781 16.639 7 16.0276 7C15.4163 7 14.9224 7.50781 14.9224 8.13636V9.38991C14.3939 9.46449 13.8897 9.59233 13.4303 9.78054C12.3078 10.2386 11.32 11.1193 11.0713 12.4759C10.9331 13.2322 10.9918 13.9425 11.282 14.5781C11.5687 15.2031 12.028 15.6541 12.5185 15.9844C13.4135 16.5861 14.6174 16.9162 15.6 17.1855L15.6097 17.1882L15.7064 17.2131C16.829 17.5185 17.6959 17.767 18.2623 18.147C18.5179 18.321 18.6457 18.4773 18.7113 18.6193C18.7701 18.7507 18.8219 18.9673 18.7562 19.3438C18.6803 19.7699 18.4143 20.125 17.8306 20.37C17.2158 20.6293 16.3074 20.7251 15.2056 20.5582C14.5183 20.4482 13.3301 20.1214 12.6083 19.7912C12.0522 19.5355 11.3994 19.7947 11.1507 20.3665C10.902 20.9382 11.1542 21.6094 11.7103 21.8651C12.6428 22.2912 14.0278 22.6641 14.8706 22.799H14.8775C14.8844 22.8008 14.8922 22.8017 14.8999 22.8026C14.9077 22.8034 14.9155 22.8043 14.9224 22.8061V24.0455C14.9224 24.674 15.4163 25.1818 16.0276 25.1818C16.639 25.1818 17.1329 24.674 17.1329 24.0455V22.8665C17.6786 22.8026 18.1932 22.6747 18.6664 22.4759C19.789 22.0036 20.6939 21.1016 20.9322 19.7521C21.0669 18.9957 21.0047 18.2855 20.7112 17.6499C20.4245 17.0249 19.9617 16.5774 19.4712 16.2472C18.582 15.6494 17.3879 15.3232 16.4055 15.0548L16.3765 15.0469L16.2763 15.0185C15.1573 14.7131 14.2938 14.4645 13.7308 14.0845C13.4787 13.9105 13.3509 13.7543 13.2887 13.6158C13.23 13.4844 13.1782 13.2713 13.2473 12.8913C13.3094 12.5433 13.5788 12.1669 14.2489 11.8935C14.9189 11.62 15.8722 11.5099 16.9533 11.6733L18.5939 11.9822C19.1914 12.0959 19.7682 11.6839 19.8788 11.0696C19.9893 10.4553 19.5921 9.86222 18.9911 9.74858L17.3332 9.43608C17.3263 9.4343 17.3203 9.43342 17.3142 9.43253C17.3082 9.43164 17.3021 9.43075 17.2952 9.42898C17.24 9.41832 17.1882 9.41122 17.1329 9.40412V8.13636Z'
                              fill='currentColor'
                            />
                          </svg>
                        }
                        accountName={salesTeamMember.username || ''}
                        profileTypeText={salesTeamMember.salesTeamName}
                        handleAccountClick={
                          isSalesTeamMemberDisabled
                            ? () => {}
                            : () => salesTeamMemberAccClick(salesTeamMember, isSalesTeamMemberActive)
                        }
                        active={isAccountActive}
                        disabled={isSalesTeamMemberDisabled}
                      />
                    )
                  }
                })
              }

              return (
                <AccountWidget
                  key={index}
                  id={`${profileStore.item.id}-${index}`}
                  icon={profileDetails.icon}
                  accountName={accountName}
                  profileTypeText={profileTypeText}
                  handleAccountClick={() => {
                    setCookies('cabinet_profile_type', ELOPAGE_PROFILE_CABINETS[profile]).then(
                      () => (window.location.href = `${ROOT_URL}/${ELOPAGE_CABINETS_URL[profile]}`)
                    )
                  }}
                  sellersList={isPayer && payerSellersList}
                  active={isAccountActive}
                />
              )
            }
          })}
        </div>

        <div className='accounts-modal__bottom-navs'>
          <div className='accounts-modal__text'>
            {hideCreateSellerProfile && <CreateSellerProfileButton onClick={becomeSellerModalToggle} />}
          </div>

          <div className='elo-modal__bottom-buttons'>
            {showAdminSwitcher && (
              <a href='/admin' className='elo-btn outline-grey'>
                <div className='fields-container'>
                  <i className='fas fa-cog' />
                  {I18n.t('react.shared.admin')}
                </div>
              </a>
            )}
            <button type='button' onClick={logOutAction} className='elo-btn outline-grey'>
              {I18n.t('react.shared.logout')}
            </button>
          </div>
        </div>

        <BecomeSellerModal
          isOpen={becomeSellerModalOpen}
          toggle={becomeSellerModalToggle}
          userStore={userStore}
          payerItem={payerStore.item}
        />
      </EloModal>
    )
  }
)
